<template>
  <v-container fluid class="height-100">
    <v-btn fab color="primary" class="back-btn" to="/">
      <v-icon color="white" size="28px"> mdi-chevron-left </v-icon>
    </v-btn>

    <v-row justify="center" align="center" class="height-100">
      <v-col lg="5" md="7" sm="9" cols="11" class="d-flex justify-center">
        <v-card class="recover-box py-8" elevation="2">
          <div>
            <img src="@/assets/logo.png" width="200px" />
          </div>
          <div class="recover-description">
            <p>
              {{
                waiting_code
                  ? "Digite o código de verificação enviado para o e-mail cadastrado"
                  : "Recuperar senha"
              }}
            </p>
          </div>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              class="mx-5"
              lazy-validation
              @submit.prevent="confirmSignup()"
            >
              <div v-if="!waiting_code">
                <v-text-field
                  v-model="username"
                  :rules="[rules.required]"
                  label="Nome de usuário ou Email"
                  outlined
                  required
                />
              </div>

              <div v-else>
                <v-text-field
                  v-model="code"
                  :rules="[rules.required]"
                  label="Código"
                  outlined
                  required
                />
                <v-text-field
                  v-model="newPassword"
                  :rules="[rules.minLength, rules.password]"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  label="Nova senha"
                  outlined
                  required
                  @click:append="showPassword = !showPassword"
                />
              </div>

              <v-btn
                block
                :disabled="!valid || loading"
                color="primary"
                class="recover-btn"
                large
                type="submit"
                @click="recoverPassword()"
              >
                <v-progress-circular
                  v-if="loading"
                  indeterminate
                  size="25"
                  width="3"
                  color="white"
                />
                <span v-else class="white--text">{{
                  waiting_code ? "Salvar senha" : "Enviar código"
                }}</span>
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Auth } from 'aws-amplify'

export default {
  data () {
    return {
      loading: false,
      valid: true,
      waiting_code: false,
      showPassword: false,
      username: '',
      code: '',
      newPassword: '',
      rules: {
        required: (value) => !!value || 'Este campo é obrigatório.',
        minLength: (value) =>
          value.length >= 8 || 'Informe pelo menos 8 caracteres.',
        password: (value) =>
          /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(value) ||
          'Sua senha deve conter no mínimo uma letra maiúscula, 1 número e 1 caractere especial.'
      }
    }
  },
  methods: {
    recoverPassword () {
      if (this.waiting_code) {
        this.resetPassword()
      } else {
        this.sendCode()
      }
    },
    sendCode () {
      this.loading = true
      Auth.forgotPassword(this.username)
        .then(() => {
          this.$toast.success(
            'Informe o código recebido por e-mail para concluir o cadastro!'
          )
          this.loading = false
          this.waiting_code = true
        })
        .catch((error) => {
          this.loading = false
          console.log('error reseting password', error)
          this.$toast.error(
            'Algo de errado aconteceu. Verifique o seu nome de usuário e tente novamente.'
          )
        })
    },
    resetPassword () {
      this.loading = true
      Auth.forgotPasswordSubmit(this.username, this.code, this.newPassword)
        .then(() => {
          this.loading = false
          this.$toast.success('Senha alterada com sucesso!')
          this.$router.push('/')
        })
        .catch((error) => {
          this.loading = false
          console.log('error reseting password', error)
          this.$toast.error(
            'Algo de errado aconteceu. Verifique o código e tente novamente.'
          )
        })
    }
  }
}
</script>

<style scoped>
.recover-box {
  background-color: #fff;
  max-width: 500px;
  width: 100%;
  padding: 10px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
}

.recover-description p {
  text-align: center;
  color: #a3a3a3;
}

.recover-btn {
  font-weight: bold;
  letter-spacing: 0 !important;
}

.back-btn {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 10;
}
</style>
